//import Config from '../_config';
import Config from '../_config_liv';
import { toast } from 'react-toastify';
const token = localStorage.getItem('token');

export const isIntersect = (a, b) => {
    return a.filter(o => {
        return b.includes(o);
    }).length > 0;
}

export const isSubset = (set, subset) => {
    return new Set([...set, ...subset]).size === set.length;
}

export const removeEmptyAttribute = (obj) => {
    return Object.entries(obj).reduce((a, [k, v]) => (v ? { ...a, [k]: v } : a), {})
}


export const request = {
    "Fetch": (method, url, body = undefined, handle_permission = true) => {
        method = method.toUpperCase();
        body = ['GET'].includes(method) ? undefined : JSON.stringify(body);
        return fetch(Config.endpoint + url, {
            method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json'
            },
            body,
            credentials: 'include'
        }).then((res) => {
            if (handle_permission) {
                if ([401, 403].includes(res.status)) {
                    toast.error("Please Sign in to continue");
                    // redirect back to login with current url
                    window.location = '/registration?returnURL=' + window.location.href;
                }
            }
            return res.json();
        })
    },
    "GET": (url) => { return request.Fetch("GET", url) },
    "POST": (url, body) => { return request.Fetch("POST", url, body) },
    "PUT": (url, body) => { return request.Fetch("PUT", url, body) },
    "DELETE": (url) => { return request.Fetch("DELETE", url) },
}