import React, { Fragment } from "react";
import Topbar from "../layouts/TopBar";

function BestPractices(){
    return(
        <Fragment>
            <Topbar/>

            <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">                    
                        <div className="page-banner-text">
                            <h1 style={{color:"#ffa800"}} className="block-title"><strong>Service and Events Best Practices</strong></h1>                          
                        </div>
                    </div>
                </div>

            {/* <!-- Main Content --> */}
    <div id="main-container">
    	<div class="content">
        	<div class="container">
            	<div class="row">
                	<div class="col-md-8 content-block">
                    	<h3 style={{color:"#003165"}}><strong>A guide to Teams Coordination during Services and Events</strong></h3>
                    	<div class="post-media">
                        	{/* <img src="https://via.placeholder.com/900x600?text=IMAGE+PLACEHOLDER" alt=""/> */}
                            <img src="https://i.imgur.com/CmlpEN0.jpg" alt=""/>
                        </div>
                        <div class="post-content">
                        <p style={{ textAlign : "justify"}}><strong>This document aims to outline steps to think about when in a live service so that interruptions to Media Production are minimised</strong>. </p> 
                        <p style={{ textAlign : "justify"}}>Whilst it is clear that we need to all work together and handle our needed responsibilities, we have to also remmeber there is an online audience and media team covering everything to broadcast to them, so this needs to be taken into consideration.</p>
                        	
                        <p style={{ textAlign : "justify"}}><li>Familiarise yourself with where the cameras are placed and try to avoid standing in front of them. If you have an absolute need to be get in the way of a placed camera, please check with the nearest available media member before doing so.</li></p>
                        <p style={{ textAlign : "justify"}}><li>It is the media team members responsibility to place and remove the lectern on the stage. This also includes the side table where drinks are placed. Please do not go up on stage to move or remove this as we place them in a good position for the camera angles.</li></p>
                        <p style={{ textAlign : "justify"}}><li>Media Members are purposely dressed in black or dark colours so as to reduce the attention drawn to them if they have to go on stage. This re-emphasizes the need for primarily media members to be the ones to go on stage. Of course there will be need for others to go on stage for other responsibilities, but please coordinate with us.</li></p>                            
                        <p style={{ textAlign : "justify"}}><li>If you see the need to wipe the lectern, please wipe it when it is on the side and not when it has been placed by the media member ready for the next speaker. Also avoid wiping it when the speaker is already making their way to the stage as the cameras are live by this point. And it is not good for the broadcast and our image to see the wiping process on a live screen.</li></p>
                        <p style={{ textAlign : "justify"}}><li>There is a stage manager from the media team always in the hall. If you are taking any part of the service, please watch the stage manager closely as they will guide and prompt you when to go up and also to start speaking.</li></p>
                        <p style={{ textAlign : "justify"}}><li>In addition to the stage manager, there is a timer on stage, which will usually be counting down. So for example, if the stage manager has prompted you to go on stage, watch for while the timer counts down to zero, and once it does, you can start speaking.</li></p>
                        <p style={{ textAlign : "justify"}}><li>Also, we are well coordinated with who is going up next, video timing etc. If there is a sudden change in the order of the service, please let the stage manager or nearest media member know and they will go on stage to make any needed changes eg, moving the lectern.</li></p>
                        <p style={{ textAlign : "justify"}}><li>Once the timer goes to zero or stage manager gives you the thumbs up, please AVOID phrases like "are we live", "can I start" and other such phrases. Just believe you are live and carry on.</li></p>
                      	</div>                        
            			
                        
                    </div>
                    
                    {/* <!-- Sidebar --> */}
                    <div class="col-md-4 sidebar-block">
                        <div class="widget tabbed_content tabs">
                            
                        </div>
                        
                        <div class="widget widget_testimonials">
                        	<h3 class="widgettitle">Summary</h3>
                            <div class="carousel-wrapper">
                                <div class="row">
                                    <ul class="owl-carousel carousel-fw" id="testimonials-slider" data-columns="1" data-autoplay="5000" data-pagination="no" data-arrows="yes" data-single-item="no" data-items-desktop="1" data-items-desktop-small="1" data-items-tablet="1" data-items-mobile="1">
                                        <li class="item">
                                            <div class="testimonial-block">
                                                <blockquote>
                                                    <p>Lets work together to deliver a great In-house and online service.</p>
                                                </blockquote>
                                                {/* <div class="testimonial-avatar"><img src="https://via.placeholder.com/100x100?text=IMAGE+PLACEHOLDER" alt="" width="70" height="70"/></div> */}
                                                <div class="testimonial-avatar"><img src="https://i.imgur.com/NKzTUVz.jpg" alt="" width="70" height="70"/></div>
                                                <div class="testimonial-info">
                                                    <div class="testimonial-info-in">
                                                        <strong>Mention any suggestions to the team or <a style={{color:"#ffa800"}} href="/improvements">add here</a></strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                       
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

        </Fragment>
    )
}

export default BestPractices