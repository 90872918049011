import React, { Fragment, useState, useEffect } from "react";
import Topbar from "../layouts/TopBar";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import ClockinResources from "../resources/ClockinResources";
import LocationResources from "../resources/LocationResources";
import { toast } from "react-toastify";
import moment from "moment/moment";
import Select from 'react-select';
import DatePicker from 'react-date-picker';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { connect } from "react-redux";
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import {ACCESS_DENIED} from '../Messages'

function ManageMembersClockin({isLoggedIn, loggedInUser}){  
  const [clockinData, setClockinData] = useState([])
  const [selectedClockinDate, setSelectedClockinDate] = useState(null); 
  const [locations, setLocations] = useState([])
  const [selectedLocation, setSelectedLocation] = useState('') 
  const [displayDateTime, setDisplayDateTime] = useState(null)

  const clockinResources = new ClockinResources()
  const locationResources = new LocationResources()
  const today = new Date();

    const loadClockinForSelectedDate = (selected_date) => {                
        return clockinResources.get_date_clockin(selected_date)
    }

    const loadSpecificClockinForSelectedDate = (clockin_data) => {                
        return clockinResources.get_specific_date_clockin(clockin_data)
    }

    const loadLocations = () => {
        return locationResources.get()
    }

    const handleClockinDateSelection = (selectedDate) => {
        setSelectedClockinDate(selectedDate)
    };   
    
    let mediaLocations = []  
      locations.map((loc, index) => (
          mediaLocations.push({value: loc['id'], label: loc['name'] })
      ))

      const handleLocationSelection = (location) => {
        setSelectedLocation(location.value);
    };

    const handleClockinLoadClick = (id) => {      
            let clockin_data = {};
    
            if(Object.keys(selectedLocation).length === 0)
                {
                    alert("Please Select the Location you want to filter by")
                    return;
                }   
            
            clockin_data['location_id'] = Number(selectedLocation)
            clockin_data['requestedDate'] = moment( selectedClockinDate ).format('YYYY-MM-DD HH:mm:ss')
            
            loadSpecificClockinForSelectedDate(clockin_data).then(response => { 
                setClockinData(response['body']);
                setDisplayDateTime(moment( selectedClockinDate).format('MMM-YYYY') )
        }).catch((err) => { 
            toast.error("Network error: " + err)
        });
          
      
    };     
    

    useEffect(() => {
        loadLocations().then(response => { 
                    setLocations(response['body']); 
                    toast.success("Successfully got data" )
                }).catch((err) => {
                    toast.error("Network error: " + err)
        }); 


        let date_formatted = moment( today).format('YYYY-MM-DD HH:mm:ss')
        loadClockinForSelectedDate(date_formatted).then(response => { 
            setClockinData(response['body']); 
            setDisplayDateTime(moment( new Date()).format('MMM-YYYY')  )
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });
    }, []);

  
    
    return(
        <Fragment>
            <Topbar/>

            <div className="hero-area">
            <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                        <div className="page-banner-text">
                        {(isLoggedIn && loggedInUser['role.name'] === 'admin')?
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>Clock in Data</strong></h1>
                                :
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>{ACCESS_DENIED}</strong></h1>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
            

            <div id="main-container">
                {
                    (isLoggedIn && loggedInUser['role.name'] === 'admin') ? 
                
                <div className="content">
                     <div className="row">
                        <div className="col-md-6">
                                <label>Select Month and Year:<span style={{color:"red"}}>*</span></label>
                                <DatePicker
                                        name="clockinDate"
                                        placeholderText="Month and Year"
                                        value={selectedClockinDate}
                                        onChange={handleClockinDateSelection}
                                        maxDate={today}
                                        dateFormat="yyyy-MM-dd" // Set desired date format
                                    />                                      
                        </div> 
                                <div style={{"paddingRight" : '100px'}} className="col-md-6">
                                           
                                    <Select
                                        name='requirements'
                                        id="requirements"
                                        classNamePrefix='select-css-tag'
                                        //value={selectedOption}
                                        onChange={handleLocationSelection}
                                        options={mediaLocations}
                                        placeholder="Select Location" 
                                        />     
                                        <div className="spacer-40"></div><div className="spacer-40"></div>
                                           <button style={{marginLeft:'30px', color: '#003165'}} onClick={handleClockinLoadClick}>Load Data</button>

                                    </div>

                                           
                    </div>

                    <div className="spacer-50"></div>

                    <div className="container">                    
                        <h3 style={{color: "#ffa800"}}><strong>Clockin Data for {displayDateTime}</strong></h3>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                <TableRow>
                                    <TableCell><strong>FirstName</strong></TableCell>
                                    <TableCell><strong>LastName</strong></TableCell>
                                    <TableCell><strong>Location</strong></TableCell>
                                    <TableCell><strong>Service</strong></TableCell>
                                    <TableCell><strong>Clockin Time</strong></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                                                      
                                {clockinData.map((data) => (
                                    
                                    <TableRow key={data['firstname']}>
                                    <TableCell>{data['user.firstname']}</TableCell>
                                    <TableCell>{data['user.lastname']}</TableCell>
                                    <TableCell>{data['location.name']}</TableCell>
                                    <TableCell>{data['service']}</TableCell>
                                    <TableCell>{data['clockin_time']}</TableCell>
                                    </TableRow> 
                                ))
                                }
                                </TableBody>
                            </Table>
                        </TableContainer>    
                        <div className="spacer-40"></div>                      

                            
                    </div>
                </div> : <></>
        }
            </div>

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageMembersClockin);