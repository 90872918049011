import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import Topbar from "../layouts/TopBar";
import Footer from "../layouts/Footer";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-datetime/css/react-datetime.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import Select from 'react-select';
import { toast } from "react-toastify";
import moment from "moment";
import {ACCESS_DENIED} from '../Messages'
import ClockinResources from "../resources/ClockinResources";
import LocationResources from "../resources/LocationResources";


function MediaClockIn({isLoggedIn, loggedInUser, logout}){
    const [time, setTime] = useState(new Date().toLocaleTimeString());
    const [locations, setLocations] = useState([])
    const [selectedLocation, setSelectedLocation] = useState('')  
    const [selectedService, setSelectedService] = useState('')

    let locationResources = new LocationResources()
    let clockinResources = new ClockinResources()

    useEffect(() => {       
        loadLocations().then(response => { 
            setLocations(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });

        const timer = setInterval(() => {
        setTime(new Date().toLocaleTimeString()); 
        }, 1000);
        // Cleanup interval on component unmount
        return () => clearInterval(timer);       
        
    }, []);

    const loadLocations = () => {
        return locationResources.get()
    }
    
    const handleLocationSelection = (location) => {
        setSelectedLocation(location);
    };

    const handleServiceSelection = (service) => {
        setSelectedService(service);
    };

    let mediaLocations = []  
    locations.map((loc, index) => (
        mediaLocations.push({value: loc['id'], label: loc['name'] })
    ))

    let services= []
    services.push({value: 'First', label: 'First' })
    services.push({value: 'Second', label: 'Second' })
    services.push({value: 'Both', label: 'Both' })
  
    // Function to handle button clicks
    const handleClockinButtonClick = () => { 
      
      if(selectedLocation.length === 0)
      {
        alert("Please Select which Service(s) you are serving at")
        return;
      }

      if(selectedLocation.length === 0)
          {
            alert("Please Enter the Location you are serving today")
            return;
          }
      let clockindata = {};
      clockindata['clockin_time'] = moment( new Date()).format('YYYY-MM-DD HH:mm:ss') 
      clockindata['service'] = selectedService['value']
      clockindata['location_id'] = selectedLocation['value']
      clockindata['user_id'] = loggedInUser['id']

      clockinResources.post(clockindata).then(res => {
        if (res.error.length < 1) {
            alert("Successfully Clocked in")
            setTimeout(() => {
                window.location.href = window.location.origin + '/';
              }, 200);
        }
         else {
            //toast.error("Operation Failed: " + res.error);
            alert("An Issue occured, please retry")
        }
        }).catch((err) => {
            toast.error("Network Error: " + err);
        });   
      
    };

    return(
        <Fragment>
            <Topbar/>

            <div className="hero-area">
            <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                    <div className="page-banner-text">
                            {isLoggedIn ?
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>Member Clock IN</strong></h1>
                                :
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>{ACCESS_DENIED}</strong></h1>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>

            <div className="spacer-10"></div>

            {/* <!-- Main Content --> */}
            <div id="main-container">
            <div style={{display: "flex",justifyContent: "center",alignItems: "center",width: "100%", paddingRight:"200px"}}
>
                <div className="content" style={{ width: "60%", height: "auto",display: "grid", placeItems: "center",padding: "20px", }}>
                    <div className="container">
                        <div className="text-align-center">
                            <h2><strong>CURRENT TIME: </strong><strong style={{color:"#ffa800"}}>{time}</strong></h2>
                        </div>
                    </div>

                    <div>
                    <div className="row">
                        <div className="col-md-6">
                            <label style={{color:"#ffa800"}} >Location</label>
                                    <Select
                                        name='requirements'
                                        id="requirements"
                                        classNamePrefix='select-css-tag'
                                        //value={selectedOption}
                                        onChange={handleLocationSelection}
                                        options={mediaLocations}
                                        placeholder="Select Location" 
                                    />  
                        </div> 

                        <div className="col-md-6">
                            <label style={{color:"#ffa800"}} >Service</label>
                                    <Select
                                        name='requirements'
                                        id="requirements"
                                        classNamePrefix='select-css-tag'
                                        //value={selectedOption}
                                        onChange={handleServiceSelection}
                                        options={services}
                                        placeholder="Select Service" 
                                    />  
                        </div>                       
                    </div>

                    <div className="spacer-50"></div>

                    <button style={{marginLeft: "10px", width:'100px', backgroundColor:"#003165"}} onClick={handleClockinButtonClick}>Clockin</button>
                   
                </div> 
                                        
                <div className="spacer-20"></div>
                {/* <div className="text-align-center">
                    <img src="https://i.imgur.com/iK2GkOm.jpg" style={{maxWidth: "100%", maxHeight:"100%", objectFit: "cover"}}/> 
                    </div>                 */}
               </div>
                </div>
            </div>

            <Footer/>     
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaClockIn);