import { request, removeEmptyAttribute } from "../helpers/utility";

let instance = null;

export default class ClockinResources {
    constructor() {
        if (!instance) {
            this.url = 'clockin';
            this.url_date = 'clockin_data';
            instance = this;
        }
        return instance;
    }

    get() {
        return request.GET(this.url)
    } 
    
    get_date_clockin(current_date) {
        return request.GET(this.url + '/' + current_date)
    }

    get_specific_date_clockin(body) {
        let post_body = { ...body };
        post_body = removeEmptyAttribute(post_body);
        return request.POST(this.url_date, post_body)
    }

    post(body) {
        let post_body = { ...body };
        post_body = removeEmptyAttribute(post_body);
        return request.POST(this.url, post_body)
    }        
    
    delete_absence(body) {
        let delete_body = { ...body };
        delete_body = removeEmptyAttribute(delete_body);
        return request.Fetch('DELETE', this.url, delete_body, false)
    }

    
}