import React, { Fragment, useState, useEffect } from "react";
import Topbar from "../layouts/TopBar";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import AbsenceResources from "../resources/AbsenceResources";
import UserResources from "../resources/UserResources";
import LocationResources from "../resources/LocationResources";
import { toast } from "react-toastify";
import moment from "moment/moment";
import Select from 'react-select';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { connect } from "react-redux";
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import { addMonths, addDays } from 'date-fns';
import {ACCESS_DENIED} from '../Messages'

function TeamAbsences({isLoggedIn, loggedInUser}){   
  const [absences, setAbsences] = useState([])
  const [locations, setLocations] = useState([])
  const [selectedLocation, setSelectedLocation] = useState('') 
  const [selectedOption, setSelectedOption] = useState({}); 
  const [users, setUsers] = useState([])
  const [absenceReason, setAbsenceReason] = useState('');
  const [dateSelectionRange, setDateSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const [dateSelectionViewerRange, setDateSelectionViewerRange] = useState({
    startDate: new Date(),
    endDate: addMonths(new Date(), 1),
    key: 'selectionViewer',
  });
  
  const today = new Date();
  const sixMonthsAgo = addMonths(new Date(), -6);
  const sixMonthsLater = addMonths(new Date(), 6);

  const locationResources = new LocationResources()
  const absenceResources = new AbsenceResources()

    const loadAbsences = (absence) => {
        let resources = new AbsenceResources()
        return resources.post_filtered(absence)
    }

    const loadAbsencesHome = () => {
        let resources = new AbsenceResources()
        return resources.get()
    }

    const loadUsers = () => {
        let userResources = new UserResources()
        return userResources.get_admin()
    }

    const loadLocations = () => {
        return locationResources.get()
    }

     // Function to handle dropdown change
     const handleDropdownChange = (event) => {
        setSelectedOption(event.value);
      }; 
      
      const handleLocationSelection = (location) => {
        setSelectedLocation(location.value);
    };

      const handleDateSelectRange = (ranges) => {
        setDateSelectionRange(ranges.selection);
      };

      const handleDateSelectViewerRange = (ranges) => {
        setDateSelectionViewerRange(ranges.selectionViewer);
        
      };

      let mediaLocations = []  
      locations.map((loc, index) => (
          mediaLocations.push({value: loc['id'], label: loc['name'] })
      ))
    
      // Function to handle textbox change
      const handleAbsenceReasonChange = (event) => {
          setAbsenceReason(event.target.value);
      };
    
      // Function to handle button clicks
      const handleAddAbsenceButtonClick = () => { 
        
        if(Object.keys(selectedOption).length === 0)
        {
          alert("Please Select your Name")
          return;
        }

        if(Object.keys(absenceReason).length === 0)
            {
              alert("Please Enter a Reason")
              return;
            }
        let absence = {};
        absence['user_id'] = loggedInUser['id']
        absence['reason'] = absenceReason
        absence['date_added'] = moment( new Date()).format('YYYY-MM-DD HH:mm:ss') 
        absence['start_date'] = moment( dateSelectionRange['startDate']).format('YYYY-MM-DD HH:mm:ss') 
        absence['end_date'] = moment( dateSelectionRange['endDate']).format('YYYY-MM-DD HH:mm:ss') 
        absenceResources.post(absence).then(res => {
            if (res.error.length < 1) {
                alert("Absence Added")
                setTimeout(() => {
                    window.location.href = window.location.origin + '/absences';
                  }, 200);
            }
             else {
                //toast.error("Operation Failed: " + res.error);
                alert("An Issue occured, please retry")
            }
            }).catch((err) => {
                toast.error("Network Error: " + err);
            });
        debugger     
  
          setTimeout(() => {
              window.location.href = window.location.origin + '/absences';
            }, 200);
      };     
  
      // Function to handle button clicks
      const handleDeleteAbsenceButtonClick = (id) => {      
        let absence = {};
        absence['id'] = Number(id)
        absence['user_email'] = loggedInUser['email']

        absenceResources.delete_absence(absence).then(res => {
            if (res.error.length < 1) {
                alert("Absence removed")
                setTimeout(() => {
                    window.location.href = window.location.origin + '/absences';
                  }, 200);
            }else if(res.error === 'You do not have permission to make this change') {
                alert("You do not have permission to make this change.")
            }
             else {
                //toast.error("Operation Failed: " + res.error);
                alert("An Issue occured, please retry")
            }
            }).catch((err) => {
                toast.error("Network Error: " + err);
            });
      
  
      };    
      
      const handleLoadAbsencesButtonClick = (id) => {      
        let absence = {};
        if(Object.keys(dateSelectionViewerRange).length === 0)
            {
              alert("Please Select the date range you want to filter by")
              return;
            }

        if(Object.keys(selectedLocation).length === 0)
            {
                alert("Please Select the Location you want to filter by")
                return;
            }

        absence['start_date'] = moment( dateSelectionViewerRange['startDate']).format('YYYY-MM-DD HH:mm:ss') 
        absence['end_date'] = moment( dateSelectionViewerRange['endDate']).format('YYYY-MM-DD HH:mm:ss')     
        absence['location_id'] = Number(selectedLocation)
        
        loadAbsences(absence).then(res => {
            if (res.error.length < 1) {
                setAbsences(res['body']); 
                toast.success("Successfully got data" )                
            }
             else {
                //toast.error("Operation Failed: " + res.error);
                alert("An Issue occured, please retry")
            }
            }).catch((err) => {
                toast.error("Network Error: " + err);
            });
      
  
      };     

    useEffect(() => {
        loadLocations().then(response => { 
            setLocations(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });  

        loadAbsencesHome().then(response => { 
            setAbsences(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });

        loadUsers().then(response => { 
            setUsers(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });
    }, []);

    let members = []  //dropdown values is the user information
    users.map((user, index) => (
        members.push({value: user, label: user['firstname'] + " " + user['lastname'] })
    ))

    return(
        <Fragment>
            <Topbar/>

            <div className="hero-area">
            <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                        <div className="page-banner-text">
                        {isLoggedIn ?
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>Team Absences</strong></h1>
                                :
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>{ACCESS_DENIED}</strong></h1>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>

            <div id="main-container">
                {
                    isLoggedIn ? 
                
                <div className="content">
                    <div className="container"> 
                    <h2 style={{color:"#ffa800"}}><strong>Absence Viewer</strong></h2>   
                    <div className="row">                
                        <div className="col-md-6">                                            
                            <DateRangePicker
                                    editableDateInputs
                                    onChange={handleDateSelectViewerRange}
                                    moveRangeOnFirstSelection={false}
                                    ranges={[dateSelectionViewerRange]}
                                    minDate={sixMonthsAgo}
                                    maxDate={sixMonthsLater}
                                />                                          
                                            
                        </div>
                        <div className="col-md-6">
                                           
                                            <Select
                                                name='requirements'
                                                id="requirements"
                                                classNamePrefix='select-css-tag'
                                                //value={selectedOption}
                                                onChange={handleLocationSelection}
                                                options={mediaLocations}
                                                placeholder="Select Location" 
                                                />     
                                            <div className="spacer-40"></div><div className="spacer-40"></div>
                                            <button style={{marginLeft:'30px', color: '#003165'}} onClick={handleLoadAbsencesButtonClick}>Load Absences</button>

                        </div> 
                        <div className="spacer-40"/>
                    </div>  
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                <TableRow>
                                    <TableCell><strong>First Name</strong></TableCell>
                                    <TableCell><strong>Last Name</strong></TableCell>
                                    <TableCell><strong>Location</strong></TableCell>
                                    <TableCell><strong>Start Date</strong></TableCell>
                                    <TableCell><strong>End Date</strong></TableCell>
                                    <TableCell><strong>Reason</strong></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {absences.map((absence) => (
                                    <TableRow key={absence['user.firstname']}>
                                    <TableCell>{absence['user.firstname']}</TableCell>
                                    <TableCell>{absence['user.lastname']}</TableCell>
                                    <TableCell>{absence['user.location.name']}</TableCell>
                                    <TableCell>{moment( absence['start_date']).format('DD MMMM YYYY')}</TableCell>
                                    <TableCell>{moment( absence['end_date']).format('DD MMMM YYYY')}</TableCell>
                                    <TableCell>{absence['reason']}</TableCell>
                                    <TableCell>{ loggedInUser !== null && loggedInUser['role.name'] === 'admin'? <button style={{marginLeft: "10px"}} onClick={() => handleDeleteAbsenceButtonClick(absence['id'])} >Remove</button> : <p></p>}</TableCell>                                     
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>    
                        <div className="spacer-40"></div>
                        <div className="spacer-40"></div>
                        <h3 style={{color: "#ffa800"}}><strong>Add your Absence as early as you can</strong></h3>
                        
                            <div>
                                <div className="row">
                                <Select
                                    name='members'
                                    id="members"
                                    classNamePrefix='select-css-tag'
                                    //value={selectedOption}
                                    onChange={handleDropdownChange}
                                    options={members}
                                    placeholder="Team Members"
                                    />
                                <div className="spacer-20"></div>

                                    <div className="col-md-6">
                                        <label>Absence Period</label>
                                            <DateRangePicker
                                                    editableDateInputs
                                                    onChange={handleDateSelectRange}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={[dateSelectionRange]}
                                                    minDate={today}
                                                /> 
                                        </div>   
                                        <div className="col-md-6">
                                           
                                            <input style={{marginLeft: "10px", marginTop: "10px"}}
                                                type="text"
                                                value={absenceReason}
                                                onChange={handleAbsenceReasonChange}
                                                placeholder="Enter Reason"
                                            />
                                            <div className="spacer-40"></div>

                                            <button style={{marginLeft: "10px", width:'100px', color: '#003165'}} onClick={handleAddAbsenceButtonClick}>Add Absence</button>

                                        </div> 
                            
                            
                                </div>   

                            </div>                         
                        

                            
                    </div>
                </div> : <></>
        }
            </div>

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamAbsences);