import React, { Fragment } from "react";
import Topbar from "../layouts/TopBar";
import {ACCESS_DENIED} from '../Messages'
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import { connect } from "react-redux";

function OnboardingDocument({isLoggedIn, loggedInUser, logout}){
    return(
        <Fragment>
            <Topbar/>

            <div className="hero-area">
                <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                        <div className="page-banner-text">
                            {isLoggedIn ?
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>Welcome and Overview</strong></h1>
                                :
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>{ACCESS_DENIED}</strong></h1>
                            }                            
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Main Content --> */}
    <div id="main-container">
    	<div class="content">
        {
            isLoggedIn ?
        	<div class="container">
            	<div class="row">
                	<div class="col-md-8 content-block">
                    	<h2>Welcome to the Team</h2>
                    	<div class="post-media">
                        	{/* <img src="https://via.placeholder.com/900x600?text=IMAGE+PLACEHOLDER" alt=""/> */}
                            <img src="https://i.imgur.com/nFbC1Us.jpg" alt=""/>
                        </div>
                        <div class="post-content">
                        	<p style={{ textAlign : "justify"}}>Glad to have you join the most dynamic team. Please take some time to read the briefing below and see the other documents for a more detailed read. We as a team hold ourselves to the highest standards during production but also ensuring we look after each other. Feel free to interact with anyone in the team if you have any questions and they will help or direct you accordingly. </p>
                            
                        	{/* <img src="https://via.placeholder.com/200x200?text=IMAGE+PLACEHOLDER" alt="" class="align-left"/> */}
                            <img src="https://i.imgur.com/BZtkvfX.jpg" alt=""  width="200" height="200" class="align-left"/>
                        	<p style={{ textAlign : "justify"}}>Main things to note for a quick start before you read the detailed documentation, please <a style={{color:"#ffa800"}} href="/registration">register</a> on this site so your details are held and see the rest of the team members. Our documentation holds more information on our operation, call time, dress code, equipments and much more.</p>
                        	<p style={{ textAlign : "justify"}}>We cover all the events of the church except told differently. We also know you might have an area of interest or expertise but we would like for everyone to know a little bit about everything. So you will experience it all. As long as you love Jesus, willing to pick up anything related to the media team operations and questions, then you are in the right place. Many of the  team members joined without any media knowledge, so join us as we serve, learn and have fun.</p>
                      	</div>
                        {/* <div class="tag-cloud">
                            <i class="fa fa-tags"></i> 
                            <a href="#">Water</a>
                            <a href="#">Students</a>
                            <a href="#">NYC</a>
                        </div> */}
                        {/* <!-- About Author --> */}
                        {/* <section class="about-author">
                            <img src="https://via.placeholder.com/100x100?text=IMAGE+PLACEHOLDER" alt="avatar" class="img-thumbnail"/>
                            <div class="post-author-content">
                                <h3>Emma Paquette <span class="label label-primary">Author</span></h3>
                                <p><strong>Beverly Barnett</strong>, is a regular writer for the Vestige Museum blog. She loves to write more about the animal species and have so much faith in the nature's doing.</p>
                            </div>
                        </section> */}
                        {/* <!-- Pagination --> */}
                        {/* <ul class="pager">
                            <li class="pull-left"><a href="#">&larr; Prev Post</a></li>
                            <li class="pull-right"><a href="#">Next Post &rarr;</a></li>
                        </ul> */}
            			
                        
                    </div>
                    
                    {/* <!-- Sidebar --> */}
                    <div class="col-md-4 sidebar-block">
                        <div class="widget tabbed_content tabs">
                            {/* <div class="tab-content">                               
                                
                                <div id="Tcomments" class="tab-pane">
                                    <div class="tag-cloud">
                                        <a href="#">Water</a>
                                        <a href="#">Students</a>
                                        <a href="#">NYC</a>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        {/* <div class="widget sidebar-widget widget_search">
                        	<div class="input-group">
                          		<input type="text" class="form-control" placeholder="Enter your keywords"/>
                          		<span class="input-group-btn">
                            		<button class="btn btn-primary" type="button"><i class="fa fa-search"></i></button>
                          		</span>
                      		</div>
                        </div> */}
                        <div class="widget widget_testimonials">
                        	<h3 class="widgettitle">Summary</h3>
                            <div class="carousel-wrapper">
                                <div class="row">
                                    <ul class="owl-carousel carousel-fw" id="testimonials-slider" data-columns="1" data-autoplay="5000" data-pagination="no" data-arrows="yes" data-single-item="no" data-items-desktop="1" data-items-desktop-small="1" data-items-tablet="1" data-items-mobile="1">
                                        <li class="item">
                                            <div class="testimonial-block">
                                                <blockquote>
                                                    <p>Welcome Welcome Welcome.</p>
                                                </blockquote>
                                                <div class="testimonial-avatar"><img src="https://i.imgur.com/BZtkvfX.jpg" alt="" width="70" height="70"/></div>
                                                <div class="testimonial-info">
                                                    <div class="testimonial-info-in">
                                                        <strong>Mention any suggestions to the team leads or add</strong><a style={{color:"#ffa800"}} href="/improvements">here</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                       
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> :<></>
        }
        </div>
    </div>

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingDocument);