import React, { Fragment, useState, useEffect } from "react";
import Topbar from "../layouts/TopBar";
import Footer from "../layouts/Footer";
import EventResources from "../resources/EventResources";
import UserResources from "../resources/UserResources";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment/moment";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { event } from "jquery";
import Select from 'react-select';
import { connect } from "react-redux";
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import DateTime from 'react-datetime';

function EventDetailsAdmin({isLoggedIn, loggedInUser}){
    const location = useLocation();

    const [mediaStartTime, setMediaStartTime] = useState(null);
    const [events, setEvents] = useState([])
    const [eventId, setEventId] = useState('')
    const [eventVolunteers, setEventVolunteers] = useState([])
    const [users, setUsers] = useState([])
    const [selectedOption, setSelectedOption] = useState({}); // stores the entire user details
    const [availabiltyInfo, setavailabiltyInfo] = useState('');
    const resources = new EventResources()
  
    // Function to handle dropdown change
    const handleDropdownChange = (event) => {
      setSelectedOption(event.value);
    };

    const handleTimeChange = (value) => {
        setMediaStartTime(value);
    };
  
    // Function to handle textbox change
    const handleAvailabilityInputChange = (event) => {
        setavailabiltyInfo(event.target.value);
                           
    };

    const handleUpdateMediaSetupTime = () => {
        const newTimeString = mediaStartTime.format('HH:mm:ss');

         // Combine the existing date part with the new time part
        let currentMediaSetupTime = events[0]['media_setup_time']
        const updatedMediaDateString = moment(currentMediaSetupTime).format('YYYY-MM-DD') + ' ' + newTimeString
        const updatedMediaDateTime = moment( updatedMediaDateString ).format('YYYY-MM-DD HH:mm:ss');
        let formData = {}
        formData['user_email'] = loggedInUser['email']
        formData['id'] = events[0]['id']
        formData['media_setup_time'] = updatedMediaDateTime

        resources.admin_put(formData).then(res => {
            if (res.error.length < 1) {
                alert("Setup Time Updated successfully")
                setTimeout(() => {
                    window.location.href = window.location.origin + '/eventsdetails?id='+events[0]['id'];
                  }, 200);
            }else if(res.error === 'You do not have permission to make this change') {
                alert("You do not have permission to make this change.")
            }
             else {
                //toast.error("Operation Failed: " + res.error);
                alert("An Issue occured, please retry")
            }
            }).catch((err) => {
                toast.error("Network Error: " + err);
            });
                           
    };

    // Function to handle button clicks
    const handleApproveEventButtonClick = (ev) => { 
        resources.admin_approve_event_post(ev).then(res => {
            if (res.error.length < 1) {
                alert("Event Approved")
                setTimeout(() => {
                    window.location.href = window.location.origin + '/manageevents';
                  }, 200);
            }
            else {
                alert("Approval Failed.")
            }
            }).catch((err) => {
                toast.error("Network Error: " + err);
            });
      };
  
      // Function to handle button clicks
      const handleRefuseEventButtonClick = (ev) => {      
        resources.admin_refuse_event_post(ev).then(res => {
            if (res.error.length < 1) {
                alert("Event Refused. Speak to Seyi to Completely remove if needed")
                setTimeout(() => {
                    window.location.href = window.location.origin + '/manageevents';
                  }, 200);
            }
            else {
                alert("Approval Process Failed.")
            }
            }).catch((err) => {
                toast.error("Network Error: " + err);
            });
      
  
      };    
  
    // Function to handle button clicks
    const handleAddVolunteerButtonClick = () => {      
        alert(events)
      
      if(Object.keys(selectedOption).length === 0)
      {
        alert("Please Select your Name")
        return;
      }
      let volunteer = {};
      volunteer['user_id'] = Number(selectedOption['id'])
      if(availabiltyInfo.length > 1 )
      {
        volunteer['availabilty_info'] = availabiltyInfo
      }
      volunteer['event_id'] = Number(eventId)
      resources.post_Volunteers(volunteer)
      
      toast("Thanks for Volunteering", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        });

        setTimeout(() => {
            window.location.href = window.location.origin + '/eventsdetails?id='+eventId;
          }, 200);
    };

    // Function to handle button clicks
    const handleDeleteVolunteerButtonClick = () => {
      if(Object.keys(selectedOption).length === 0)
      {
        alert("Please Select your Name")
        return;
      }
      let volunteer = {};
      volunteer['user_id'] = Number(selectedOption['id'])
      volunteer['event_id'] = Number(eventId)
      resources.delete_Volunteer(volunteer)

      setTimeout(() => {
        window.location.href = window.location.origin + '/eventdetailsadmin?id='+eventId;
      }, 200);

    };

    

    const loadEvent = (eventId, loggedInUserID) => {        
        return resources.get_adminSingleEvent(eventId, loggedInUserID)
    }

    const loadEventVolunteers = (eventId) => {
        return resources.get_adminVolunteers(eventId)
    }

    const loadUsers = () => {
        let userResources = new UserResources()
        return userResources.get_admin()
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        //const searchParamss = queryString.parse(location.search)
        
         const eventId = searchParams.get('id');
         setEventId(eventId)

        loadEvent(eventId, loggedInUser['id']).then(response => { 
            setEvents(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });

        loadEventVolunteers(eventId).then(response => { 
            setEventVolunteers(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });

        loadUsers().then(response => { 
            setUsers(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });
        
    }, []);

    let members = []  //dropdown values is the user information
    users.map((user, index) => (
        members.push({value: user, label: user['firstname'] + " " + user['lastname'] })
    ))
    return(        
        <Fragment>
            <Topbar/>
             <div className="hero-area">
             <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">                    
                        <div className="page-banner-text">
                            <h1 style={{color:"#ffa800"}} className="block-title"><strong>Event Details</strong></h1>                                                      
                        </div>
                    </div>
                </div>
            </div>

            {/* <!--Main Content> */}
            <div id="main-container">
                <div className="content">                    
                    <div className="container">
                    <div className="row">   
                    <div className="grid-item event-grid-item format-standard">
                        
                        {
                            // ToDO: another component for event details for only team members
                            events.length > 0 && ((events[0]['team_members_only'] === 'True' && isLoggedIn) || events[0]['team_members_only'] === 'False') ?
                        
                        <div className="grid-item-inner"><a href={events && events.length > 0 && events[0]['image']} className="media-box" target="_blank">  
                            {events && events.length > 0 && (
                                <img src={events[0]['image']} style={{width: "800px", height: "200px"}} alt=""/>
                                )}   
                            </a>
                            
                            {events.map((event, idx) => (
                                <div className="grid-item-content" key = {idx} >
                                <span style={{backgroundColor:"#ffa800"}} className="event-date">
                                    <span className="date">{moment(event['event_date']).format('DD')}</span>
                                    <span className="month">{moment(event['event_date']).format('MMM')}</span>  
                                    <span className="year">{moment(event['event_date']).format('YYYY')}</span>
                                </span>
                                <span className="meta-data">{moment(event['start_time']).format('dddd, HH:mm')}</span>
                                <h2 className="post-title"><p style={{color:"#003165"}}>{event['name']}</p></h2>                                
                                <div className="spacer-20"></div>
                                <ul className="list-group">                                    
                                    <li className="list-group-item">{event['event_venue']}<span className="badge">Location</span></li>
                                    <li className="list-group-item">{event['number_attendees']}<span className="badge">Attendees</span></li>
                                    <li className="list-group-item">{event['event_requirements']}<span className="badge">Event Requirements</span></li>                                    
                                    { isLoggedIn ? <li className="list-group-item">{event['contact_name'] + "  " + event['phone'] }<span className="badge">Event Contact</span></li>:<></>}
                                    <li className="list-group-item">{event['other_detail']}<span className="badge">Other details</span></li>
                                    
                                </ul>
                                <div className="spacer-20"></div>
                                 {
                                    isLoggedIn ? 
                                    <div>
                                        <h2 className="post-title"><p style={{color:"#003165"}}>{"MEDIA SETUP TIME: " + moment(event['media_setup_time']).format('dddd, HH:mm')}</p></h2>
                                        
                                        {loggedInUser['role.name'] === 'admin' ? 
                                        <div> 
                                                <DateTime
                                                value={mediaStartTime}
                                                onChange={(value) => handleTimeChange(value)}
                                                dateFormat={false}
                                                timeFormat="HH:mm"
                                            />
                                            <button style={{marginLeft: "10px"}} onClick={handleUpdateMediaSetupTime}>Change Time</button>
                                        </div>
                                         :  <p/>
                                    }
                                        
                                        
                                    </div>
                                    
                                    :<p></p>
                                    
                                 }


                                 
                            </div>
                                 
                            ))}
                            
                        </div>:<><h1 style={{color:"#ffa800"}} className="block-title"><strong>No Access to this Event Details</strong></h1>  </>
                    }
                    </div> 
                    
                    </div>
                    {
                        isLoggedIn ? 
                        <div>
                        <h3>Media Volunteers</h3>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                <TableRow>
                                    <TableCell><strong>FirstName</strong></TableCell>
                                    <TableCell><strong>LastName</strong></TableCell>
                                    <TableCell><strong>Availability Info</strong></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {eventVolunteers.map((volunteer) => (
                                    <TableRow key={volunteer['event_id']}>
                                        <TableCell>{volunteer['user.firstname']}</TableCell>
                                        <TableCell>{volunteer['user.lastname']}</TableCell>
                                        <TableCell>{volunteer['availabilty_info']}</TableCell>                                        
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>  
                        </div>
                        : <p></p>
                    }
                    

                          

                        <div className="spacer-20"></div>
                        <div className="spacer-20"></div>
                    {
                        isLoggedIn ? 
                        <div>
                            <h3 className="post-title"><p style={{color:"#0096FF"}}>Volunteer Availability</p></h3>

                            <h6 className="post-title"><p style={{color:"#8B0000"}}>If no additional details are provided, it will taken that you are availble from setup time to event end</p></h6>
                            <div className="row">
                                <Select
                                    name='members'
                                    id="members"
                                    classNamePrefix='select-css-tag'
                                    //value={selectedOption}
                                    onChange={handleDropdownChange}
                                    options={members}
                                    placeholder="Team Members"
                                    />
                                
                                <input style={{marginLeft: "10px", marginTop: "10px"}}
                                    type="text"
                                    value={availabiltyInfo}
                                    onChange={handleAvailabilityInputChange}
                                    placeholder="Other Availablity info"
                                />
                                <button style={{marginLeft: "10px"}} onClick={handleAddVolunteerButtonClick}>Add</button>
                                <button style={{marginLeft: "10px"}} onClick={handleDeleteVolunteerButtonClick}>Delete</button>
                            </div> 
                        </div>
                        : <p></p>
                    }

                    <div className="spacer-40"></div>
                    { 

                        (loggedInUser !== null && loggedInUser['role.name'] === 'admin' ) ?
                        events.map((ev, idx) => (
                            ev['enabled'] === '0' ? 
                        <div>
                            <button style={{ backgroundColor : "#8ccd80", color : "#003165" , marginLeft: "10px"}} onClick={() => handleApproveEventButtonClick(ev)} >Approve Event</button>
                            
                            <button style={{backgroundColor : "#d80000", color : "#003165" , marginLeft: "10px"}} onClick={() => handleRefuseEventButtonClick(ev)} >Refuse Event</button>
                        </div> : <></>
                        ))
                        :<></>
                    }
                           
                    </div>
                </div>
            </div>
            <Footer/>

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(EventDetailsAdmin);