import React, { Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { loginSuccess, setUser } from '../redux/actions/LoggedInAction';
import Topbar from "../layouts/TopBar";
import Footer from "../layouts/Footer";
import { ToastContainer, toast } from 'react-toastify';
import LocationResources from "../resources/LocationResources";
import UserResources from "../resources/UserResources";
import RotaResources from "../resources/RotaResources";
import Select from 'react-select';
import moment from "moment";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

function validate(firstname, lastname, email, password, phone, address1, city, post_code, country, date_of_joining, date_of_birth, location_id, about, competencies) {
    return {
      firstname: firstname.length === 0,
      lastname: lastname.length === 0,
      email: email.length === 0,
      password: password.length === 0,
      phone: phone === 0,
      address1: address1.length === 0,
      city: city.length === 0,
      post_code: post_code.length === 0,
      country: country.length === 0,
      date_of_joining: date_of_joining.length === 0,
      date_of_birth: date_of_birth.length === 0,
      location_id: location_id.length === 0,
      about: about.length === 0,
      competencies: competencies.length === 0,
    };
  }

function MemberRegistration({loginSuccess, setUser}){
    const [locations, setLocations] = useState([])
    const [selectedJoiningDate, setSelectedJoiningDate] = useState(null); 
    const [rotaRoles, setRotaRoles] = useState([])
    const [selectedMediaRoles, setSelectedMediaRoles] = useState([]);
    const [birthday, setBirthday] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [isFortifyChecked, setIsFortifyChecked] = useState(false);
    const [isConnectChecked, setIsConnectChecked] = useState(false);
    const [isServeChecked, setIsServeChecked] = useState(false);
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        address1: '',
        city: '',
        post_code: '',
        phone: '',
        country: '',
        about: '',
        date_of_joining: null, // Assuming it's a Date object, initialize it appropriately
        date_of_birth: null, // Assuming it's a Date object, initialize it appropriately
        location_id: '',
        email: '',
        password: '',
      });

      const [formLoginData, setformLoginData] = useState({
        email: '',
        password: '',
      });
    
    
    const userImageAlbumId = '2hwMk0B' // imgur album id
    const locationResources = new LocationResources()
    const userResources = new UserResources()
    const rotaResources = new RotaResources()
    const today = new Date();
    

    const handleDateOfJoiningChange = (date) => {
        setSelectedJoiningDate(date);
    };

    const handleBirthdayChange = (date) => {
        setBirthday(date);
    };    

    const handleFortifyCheckboxChange = (event) => {
        setIsFortifyChecked(event.target.checked);
    };

    const handleConnectCheckboxChange = (event) => {
        setIsConnectChecked(event.target.checked);
    };

    const handleServeCheckboxChange = (event) => {
        setIsServeChecked(event.target.checked);
    };

    const loadLocations = () => {
        return locationResources.get()
    }

    const loadRotaRoles = () => {
        return rotaResources.get_rota_roles()
    }

    // ToDO: renable this method to update state with date
    // const handleDateChange = (name, date) => {
    //     setFormData((prevData) => ({
    //       ...prevData,
    //       [name]: date,
    //     }));
    //   };

    const handleCountrySelection = (selectedCountry) => {
        setFormData((prevData) => ({
          ...prevData,
          country: selectedCountry['value'],
        }));
      };
      
    const handleSelection = (selectedOptions) => {
        setFormData((prevData) => ({
          ...prevData,
          location_id: selectedOptions['value'],
        }));
      };

    const handleMediaSkillsSelection = (skills) => {
        setSelectedMediaRoles(skills)
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handleLoginInputChange = (e) => {
        const { name, value } = e.target;
        setformLoginData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImageFile(file);
      };  

      const uploadToImgur = async () => {
        if (!imageFile) {
            alert('Please select a file.');
            return;
        }    

        try {
          const formData = new FormData();
          formData.append('image', imageFile);
          formData.append('album', userImageAlbumId);
    
          const response = await fetch('https://api.imgur.com/3/image', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ` + process.env.REACT_APP_Bearer_Token.toString(), 
            },
            body: formData,
          });
    
          const data = await response.json();
    
          if (data.success) {
            setImageUrl(data.data.link);   
            //addToMemberAlbum(data.data.id)         
            alert('Image Successfully uploaded. Now complete the rest of the form and submit.');
          } else {
            alert('Error Uploading Image, please try again');
          }
        } catch (error) {
          alert('Error Uploading Image, please try again');
        }
      };   
      
    const canBeSubmitted = (dataToSubmit) => {
        const errors = validate(dataToSubmit['firstname'], dataToSubmit['lastname'], dataToSubmit['email'], dataToSubmit['password'],  dataToSubmit['phone'], dataToSubmit['address1'], 
        dataToSubmit['city'], dataToSubmit['post_code'], dataToSubmit['country'],dataToSubmit['date_of_joining'],dataToSubmit['date_of_birth'],dataToSubmit['location_id'],
        dataToSubmit['about'], dataToSubmit['competencies']);
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return !isDisabled;
    }
    

    const handleLoginSubmit = (e) => {
        e.preventDefault();   
        if (formLoginData.email.length === 0 || formLoginData.password.length === 0){
            alert("Enter username and password")
            return
        }
        userResources.user_post_login(formLoginData).then(res => {
            if (res.error.length < 1) {
                loginSuccess();                
                localStorage.setItem('token', res['body']['token']);
                setUser(res['body'])
                alert("Successfully logged in.")
                setTimeout(() => {
                    window.location.href = window.location.origin + '/';
                  }, 200);
            } else {
                alert("Login Failed, please check details entered.")
            }
            }).catch((err) => {
                toast.error("Network Error: " + err);
            });

       
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        let submitFormData = formData
        // ToDo: Fix this hack: get all data from state
        let media_roles =[]        
        selectedMediaRoles.map((role) => (
            media_roles.push(role['value'])
        ))
        const mediaRoles = media_roles.join(', ')
        submitFormData['competencies'] = mediaRoles
        submitFormData['image'] = imageUrl
        submitFormData['date_of_joining'] = moment( selectedJoiningDate ).format('YYYY-MM-DD HH:mm:ss') 
        submitFormData['date_of_birth'] = moment( birthday ).format('YYYY-MM-DD HH:mm:ss') 
        submitFormData['done_connect'] =  isConnectChecked
        submitFormData['done_fortify'] = isFortifyChecked 
        submitFormData['done_serve'] = isServeChecked
        if (!canBeSubmitted(submitFormData)){
            alert("Remember to fill out the mandatory fields")
            return
        }
        userResources.admin_post(submitFormData).then(res => {
            if (res.error.length < 1) {
                alert("Thanks for registering! Your Profile is pending approval")
                setTimeout(() => {
                    window.location.href = window.location.origin + '/about';
                  }, 200);
            }else if(res.error === 'Email already in use') {
                alert("Registration failed, Email Already in Use.")
            }
            else {
                alert("Registration Failed, please check details entered.")
            }
            }).catch((err) => {
                toast.error("Network Error: " + err);
            });

       
      };

    useEffect(() => {     
        loadLocations().then(response => { 
            setLocations(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });  

        loadRotaRoles().then(response => { 
            setRotaRoles(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });  

    }, []);
    
    let mediaLocations = []  
    locations.map((loc, index) => (
        mediaLocations.push({value: loc['id'], label: loc['name'] })
    ))

    let mediaRoles = []  
    rotaRoles.map((rotaRole, index) => (
        mediaRoles.push({value: rotaRole['name'], label: rotaRole['name'] })
    ))
    mediaRoles.push({value: 'Photography', label: 'Photography' })
    mediaRoles.push({value: 'Camera close up', label: 'Camera close up' })
    mediaRoles.push({value: 'Roaming Camera', label: 'Roaming Camera' })
    mediaRoles = mediaRoles.filter(role => role.value !== 'Camera 1');
    mediaRoles = mediaRoles.filter(role => role.value !== 'Roam 1');
    mediaRoles = mediaRoles.filter(role => role.value !== 'Roam 2');

    let countries= []
    countries.push({value: 'United Kingdom', label: 'United Kingdom' })
    countries.push({value: 'Nigeria', label: 'Nigeria' })

    return(
        <Fragment>
            <Topbar/>

            <div className="hero-area">
            {/* <div className="page-banner parallax" style={{backgroundImage: "url(assets/images/testjpeg1280.jpg)"}}> */}
            <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                        <div className="page-banner-text">
                            <h1 style={{color:"#ffa800"}} className="block-title"><strong>Enter Your Details</strong></h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="spacer-10"></div>

            {/* <!-- Main Content --> */}
            <div id="main-container">
                <div className="content">
                        <div className="text-align-center">
                            <h2>Member Login</h2>
                        </div>
                <div className="container" id="openings" style={{alignItems:"center"}} >                        
                        <div className="col-md-12" style={{alignItems:"center"}}>                	
                            <div className="col-md-10 col-sm-10 mx-auto">
                                <div className="well widget widget_volunteer_form">
                                    <form onSubmit={handleLoginSubmit}>                                    
                                    <div className="row">                                        
                                        
                                        <div className="spacer-20"></div>
                                        <div className="col-md-6">
                                            <label>Email<span style={{color:"red"}}>*</span></label>
                                            <input type="email" className="form-control"
                                                name="email"
                                                value={formLoginData.email}
                                                onChange={handleLoginInputChange}
                                            />
                                        </div> 
                                        <div className="col-md-6">
                                            <label>Password<span style={{color:"red"}}>*</span></label>
                                            <input type="password" className="form-control"
                                                name="password"
                                                value={formLoginData.password}
                                                onChange={handleLoginInputChange}
                                            />
                                        </div>                                                                            
                                    </div> 
                                        <div className="spacer-30"></div>                                        
                                        <input style={{backgroundColor:"#003165"}} type="submit" className="btn btn-primary" value="Login"/>
                                        <div className="spacer-10"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="spacer-20"></div>
                    <div className="spacer-40"></div>

                    <div className="container">
                        <div className="text-align-center">
                            <h2>Member Registration</h2>
                        </div>
                    </div>                   
                    <div className="spacer-20"></div>
                    <div className="container" id="openings" style={{alignItems:"center"}} >                        
                        <div className="col-md-12" style={{alignItems:"center"}}>                	
                            <div className="col-md-10 col-sm-10 mx-auto">
                                <div className="well widget widget_volunteer_form">
                                    <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <h4 style={{color:"#ffa800"}}><strong>Essential you provide requested details</strong></h4>
                                            <div className="col-md-6">
                                                <label>First Name<span style={{color:"red"}}>*</span></label>
                                                        <input type="text" className="form-control"
                                                            name="firstname"
                                                            value={formData.firstname}
                                                            onChange={handleInputChange}
                                                        />  

                                            </div>
                                            <div className="col-md-6">
                                                <label>Last Name<span style={{color:"red"}}>*</span></label>
                                                <input type="text" className="form-control"
                                                    name="lastname"
                                                    value={formData.lastname}
                                                    onChange={handleInputChange}
                                                />
                                            </div>    

                                            <div className="col-md-6">
                                            <label>Address<span style={{color:"red"}}>*</span></label>
                                                    <input type="text" className="form-control"
                                                         name="address1"
                                                         value={formData.address1}
                                                         onChange={handleInputChange}
                                                    />  

                                        </div>
                                        <div className="col-md-6">
                                            <label>City<span style={{color:"red"}}>*</span></label>
                                            <input type="text" className="form-control"
                                                name="city"
                                                value={formData.city}
                                                onChange={handleInputChange}
                                            />
                                        </div>  
                                        <div className="col-md-6">
                                            <label>Postcode<span style={{color:"red"}}>*</span></label>
                                            <input type="text" className="form-control"
                                                    name="post_code"
                                                    value={formData.post_code}
                                                    onChange={handleInputChange}
                                            />
                                        </div> 
                                        <div className="col-md-6">
                                            <label>Phone Number<span style={{color:"red"}}>*</span></label>
                                            <input type="number" className="form-control"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">  
                                    
                                    <div className="col-md-6">
                                    <label>Country<span style={{color:"red"}}>*</span></label>
                                        <Select
                                            name='country-drpdwn'
                                            id="country-drpdwn"
                                            classNamePrefix='select-css-tag'
                                            //value={selectedOption}
                                            onChange={handleCountrySelection}
                                            options={countries}
                                            placeholder="Choose one"
                                            />    
                                        </div>  
                                        

                                        <div className="col-md-6">
                                            <label>Media Competencies<span style={{color:"red"}}>*</span></label>
                                            <Select
                                                name='media-skills'
                                                id="media-skills"
                                                classNamePrefix='select-css-tag'
                                                isMulti
                                                //value={selectedOption}
                                                onChange={handleMediaSkillsSelection}
                                                options={mediaRoles}
                                                placeholder="Select all that apply"
                                                />  
                                        </div>  
                                        <div className="spacer-30"></div>
                                                
                                        <label>About You<span style={{color:"red"}}>*</span></label>
                                        <textarea cols="4" rows="4" id="message" name="about" value={formData.about}
                                                    onChange={handleInputChange}  className="form-control input-lg" 
                                                    placeholder="Short Bio - Why you joined media, Career, Skillset, interests etc">                                                        
                                        </textarea>

                                        <div className="spacer-40"></div>

                                        <div className="col-md-4">
                                            <label>Done Fortify:<span style={{color:"red"}}>*</span></label>
                                            <input
                                                    type="checkbox"
                                                    checked={isFortifyChecked}
                                                    onChange={handleFortifyCheckboxChange}
                                                    />
                                        </div> 

                                        <div className="col-md-4">
                                            <label>Done Connect:<span style={{color:"red"}}>*</span></label>
                                            <input
                                                    type="checkbox"
                                                    checked={isConnectChecked}
                                                    onChange={handleConnectCheckboxChange}
                                                    />
                                        </div> 

                                        <div className="col-md-4">
                                            <label>Done Serve:<span style={{color:"red"}}>*</span></label>
                                            <input
                                                    type="checkbox"
                                                    checked={isServeChecked}
                                                    onChange={handleServeCheckboxChange}
                                                    />
                                        </div> 

                                        <div className="spacer-50"></div>
                                        

                                        <div className="col-md-6">
                                        <label>Date of Joining:<span style={{color:"red"}}>*</span></label>
                                            <DatePicker
                                                    name="dateOfJoining"
                                                    placeholderText="When you joined the team"
                                                    value={selectedJoiningDate}
                                                    onChange={handleDateOfJoiningChange}
                                                    maxDate={today}
                                                    dateFormat="yyyy-MM-dd" // Set desired date format
                                                /> 
                                        </div> 
                                        <div className="col-md-6">
                                            <label><span style={{color:"red"}}>*</span>Birthday(Choose any Year): </label>
                                            <DatePicker
                                                    name="birthday"
                                                    placeholderText="Pick Date"
                                                    value={birthday}
                                                    onChange={handleBirthdayChange}
                                                    maxDate={today}
                                                    dateFormat="yyyy-MM-dd" // Set desired date format
                                                />    
                                        </div>
                                        <div className="spacer-40"></div>
                                        <div className="col-md-6">
                                            <label>Primary Service Location<span style={{color:"red"}}>*</span></label>
                                            <Select
                                                name='requirements'
                                                id="requirements"
                                                classNamePrefix='select-css-tag'
                                                //value={selectedOption}
                                                onChange={handleSelection}
                                                options={mediaLocations}
                                                placeholder="Locations"
                                                />  
                                        </div>     
                                        <div className="col-md-6">
                                            <label>Face Card- Add image<span style={{color:"red"}}>*</span></label>
                                                <input 
                                                className="form-control-alternative" 
                                                type="file" 
                                                name="productImages" 
                                                id="images" 
                                                onChange={handleImageChange}/>
                                                <button type="submit" onClick={uploadToImgur}>Upload</button>
                                        </div>     
                                        <div className="spacer-20"></div>
                                        <div className="col-md-6">
                                            <label>Email<span style={{color:"red"}}>*</span></label>
                                            <input type="email" className="form-control"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                            />
                                        </div> 
                                        <div className="col-md-6">
                                            <label>Password<span style={{color:"red"}}>*</span></label>
                                            <input type="password" className="form-control"
                                                name="password"
                                                value={formData.password}
                                                onChange={handleInputChange}
                                            />
                                        </div>                                                                            
                                    </div> 
                                        <div className="spacer-30"></div> 
                                        <h3 style={{'color':'#A52A2A'}}>Please Make sure you clicked Upload on your Image before Clicking Submit</h3>                                       
                                        <input style={{backgroundColor:"#003165"}} type="submit" className="btn btn-primary" value="Submit"/>
                                        <div className="spacer-10"></div>
                                    </form>
                                    {/* <p className="small short">Address is not mandatory</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

            <Footer/>     
        </Fragment>
    )
}

const mapDispatchToProps = {
    loginSuccess,
    setUser,
};

export default connect(null, mapDispatchToProps)(MemberRegistration);
