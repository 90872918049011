import React, { Fragment, useState, useEffect } from "react";
import Topbar from "../layouts/TopBar";
import Footer from "../layouts/Footer";
import EventResources from "../resources/EventResources";
import UserResources from "../resources/UserResources";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment/moment";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { event } from "jquery";
import Select from 'react-select';
import { connect } from "react-redux";
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import DateTime from 'react-datetime';

function EventDetailsNonMembers({isLoggedIn, loggedInUser}){
    const location = useLocation();

    const [events, setEvents] = useState([])
    const [eventId, setEventId] = useState('')
    const resources = new EventResources()

    const loadEvent = (eventId) => {
        return resources.get_non_single_event(eventId)
    }
  

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        //const searchParamss = queryString.parse(location.search)
        
         const eventId = searchParams.get('id');
         setEventId(eventId)

        loadEvent(eventId).then(response => { 
            setEvents(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });
        
    }, []);


    return(        
        <Fragment>
            <Topbar/>
             <div className="hero-area">
             <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">                    
                        <div className="page-banner-text">
                            <h1 style={{color:"#ffa800"}} className="block-title"><strong>Event Details</strong></h1>                                                      
                        </div>
                    </div>
                </div>
            </div>

            {/* <!--Main Content> */}
            <div id="main-container">
                <div className="content">                    
                    <div className="container">
                    <div className="row">   
                    <div className="grid-item event-grid-item format-standard">
                        
                        {
                            events.length > 0 && ((events[0]['team_members_only'] === 'True' && isLoggedIn) || events[0]['team_members_only'] === 'False') ?
                        
                        <div className="grid-item-inner"><a href={events && events.length > 0 && events[0]['image']} className="media-box" target="_blank">  
                            {events && events.length > 0 && (
                                <img src={events[0]['image']} style={{width: "800px", height: "200px"}} alt=""/>
                                )}   
                            </a>
                            
                            {events.map((event, idx) => (
                                <div className="grid-item-content" key = {idx} >
                                <span style={{backgroundColor:"#ffa800"}} className="event-date">
                                    <span className="date">{moment(event['event_date']).format('DD')}</span>
                                    <span className="month">{moment(event['event_date']).format('MMM')}</span>  
                                    <span className="year">{moment(event['event_date']).format('YYYY')}</span>
                                </span>
                                <span className="meta-data">{moment(event['start_time']).format('dddd, HH:mm')}</span>
                                <h2 className="post-title"><p style={{color:"#003165"}}>{event['name']}</p></h2>                                
                                <div className="spacer-20"></div>
                                <ul className="list-group">                                    
                                    <li className="list-group-item">{event['event_venue']}<span className="badge">Location</span></li>
                                    <li className="list-group-item">{event['number_attendees']}<span className="badge">Attendees</span></li>
                                    <li className="list-group-item">{event['event_requirements']}<span className="badge">Event Requirements</span></li>                                    
                                    <li className="list-group-item">{event['contact_name']}<span className="badge">Event Contact</span></li> 
                                    
                                </ul>
                                <div className="spacer-20"></div>
                                 
                            </div>
                                 
                            ))}
                            
                        </div>:<><h1 style={{color:"#ffa800"}} className="block-title"><strong>No Access to this Event Details</strong></h1>  </>
                    }
                    </div> 
                    
                    </div>      
                        <div className="spacer-20"></div>
                        <div className="spacer-20"></div>
                    
                    </div>
                </div>
            </div>
            <Footer/>

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(EventDetailsNonMembers);